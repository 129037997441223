import React, { useRef, useEffect, useState } from 'react';
import './HomePage.css';
import Header from '../components/Header';
import css_icon from '../images/css_icon.svg';
import html_icon from '../images/html_icon.svg';
import javascript_icon from '../images/javascript_icon.svg';
import react_icon from '../images/react_icon.svg';
import node_icon from '../images/node_icon.svg';
import express_icon from '../images/express_icon.svg';
import yosemite_mockup from '../images/yosemite_mockup.png';
import hackernews_mockup from '../images/hackernews_mockup.png';
import dogfriendly_mockup from '../images/dogfriendly_mockup.png';
import profile from '../images/profile.png';
import { BsGithub, BsGlobe2, BsLinkedin, BsFillSendFill } from 'react-icons/bs';

const HomePage = () => {
  const main = useRef(null);
  const about = useRef(null);
  const projects = useRef(null);
  const contact = useRef(null);
  const [contactSectionActive, setContactSectionActive] = useState(false);
  const [introSectionActive, setIntroSectionActive] = useState(true);

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const sectionsToCheck = [about, projects, contact];

      sectionsToCheck.forEach((sectionRef) => {
        if (sectionRef.current) {
          const sectionRect = sectionRef.current.getBoundingClientRect();
          const sectionTitle =
            sectionRef.current.querySelector('.section-title');
          const columnIcon = sectionRef.current.querySelector('.translate');
          const columnImages =
            sectionRef.current.querySelectorAll('.column-image');
          if (sectionRect.top <= windowHeight && sectionRect.bottom >= 0) {
            sectionTitle.style.opacity = 1;
            if (columnIcon) {
              columnIcon.style.transform = 'translateX(0)';
            }
            if (columnImages) {
              columnImages.forEach((columnImage) => {
                columnImage.style.transform = 'scale(1)';
              });
            }
          }
        }
      });

      if (contact.current) {
        const contactRect = contact.current.getBoundingClientRect();
        if (contactRect.top <= windowHeight && contactRect.bottom >= 0) {
          setContactSectionActive(true);
        } else {
          setContactSectionActive(false);
        }
      }

      if (main.current) {
        const mainRect = main.current.getBoundingClientRect();
        if (mainRect.top <= windowHeight && mainRect.bottom >= 0) {
          setIntroSectionActive(true);
        } else {
          setIntroSectionActive(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Header
        refs={{ about, projects, contact }}
        handleLogoClick={handleLogoClick}
      />
      <section className="page-home">
        <div className="intro-home">
          <div className="shape-container">
            <div className="shape shape-one"></div>
            <div className="shape shape-two"></div>
          </div>
          <div id="circle">
            <div className="arrow" onClick={() => scrollToSection(projects)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 300 300"
              enableBackground="new 0 0 300 300"
              xmlSpace="preserve">
              <defs>
                <path
                  id="circlePath"
                  d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                />
              </defs>
              <circle cx="150" cy="100" r="75" fill="none" />
              <g>
                <use xlinkHref="#circlePath" fill="none" />
                <text fill="#fff">
                  <textPath xlinkHref="#circlePath">
                    VIEW PROJECTS VIEW PROJECTS VIEW PROJECTS
                  </textPath>
                </text>
              </g>
            </svg>
          </div>
          <div
            className={`link-wrapper side ${
              contactSectionActive || introSectionActive ? 'none' : ''
            }`}>
            <div className="intro-link slide-in bottom">
              <div className="email">
                <a
                  href="mailto:gyulikimwork@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div className="link-btn-icon">
                    <BsFillSendFill />
                  </div>
                </a>
              </div>
              <div className="github">
                <a
                  href="https://github.com/gyuli-zoeykim"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div className="link-btn-icon">
                    <BsGithub />
                  </div>
                </a>
              </div>
              <div className="linkedin">
                <a
                  href="https://www.linkedin.com/in/gyulikimwork/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div className="link-btn-icon">
                    <BsLinkedin />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="intro-home-wrapper">
            <div className="intro-full">
              <p className="intro-text-one slide-in top">Hello, I'm Gyuli</p>
              <p className="intro-text-two">Full Stack Web Developer</p>
              <p className="intro-text-three slide-in bottom">
                Continuously growing, proactively seeking feedback and being a
                team player.
              </p>
              <div className="link-wrapper main" ref={main}>
                <div className="intro-link slide-in bottom">
                  <div className="btn email">
                    <a
                      href="mailto:gyulikimwork@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer">
                      <div className="link-btn-icon">
                        <BsFillSendFill />
                      </div>
                    </a>
                  </div>
                  <div className="btn github">
                    <a
                      href="https://github.com/gyuli-zoeykim"
                      target="_blank"
                      rel="noopener noreferrer">
                      <div className="link-btn-icon">
                        <BsGithub />
                      </div>
                    </a>
                  </div>
                  <div className="btn linkedin">
                    <a
                      href="https://www.linkedin.com/in/gyulikimwork/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <div className="link-btn-icon">
                        <BsLinkedin />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="project container" ref={projects}>
          <div className="row">
            <h3 className="section-title">&nbsp;</h3>
          </div>
          <div className="row two">
            <div className="column one">
              <h5 className="project-title inital">Yosemite National Park</h5>
              <p className="project-number">01</p>
              <p className="section-description">
                Built a Yosemite National Park informational website, inspired
                by a recent family trip.
              </p>
              <div className="column-icon">
                <img
                  src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=%23F7DF1E"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/css3-%231572B6.svg?style=for-the-badge&logo=css3&logoColor=white"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/html5-%23E34F26.svg?style=for-the-badge&logo=html5&logoColor=white"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/netlify-%23000000.svg?style=for-the-badge&logo=netlify&logoColor=#00C7B7"
                  alt="icon"
                />
              </div>
              <div className="project-btn">
                <div className="github">
                  <a
                    href="https://github.com/gyuli-zoeykim/yosemite-park"
                    target="_blank"
                    rel="noopener noreferrer">
                    <BsGithub className="project-btn-icon" />
                    <div className="project-btn-text">Github</div>
                  </a>
                </div>
                <div className="live">
                  <a
                    href="https://gyulizoeykim.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <BsGlobe2 className="project-btn-icon" />
                    <div className="project-btn-text">Live</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="column two image-wrapper">
              <div className="column-image">
                <a
                  href="https://gyulizoeykim.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link">
                  <div className="image-overlay"></div>
                  <img
                    src={yosemite_mockup}
                    alt="project-preview"
                    className="hover-opacity"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row two opposite project-two">
            <div className="column two image-wrapper">
              <div className="column-image">
                <a
                  href="https://gyulizoeykimwork.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link">
                  <div className="image-overlay"></div>
                  <img
                    src={hackernews_mockup}
                    alt="project-preview"
                    className="hover-opacity"
                  />
                </a>
              </div>
            </div>
            <div className="column one">
              <h5 className="project-title">Hacker News Clone</h5>
              <p className="project-number right">02</p>
              <p className="section-description">
                Built a clone of Hacker News website. My goal was to replicate
                the main layout and functionalities of Hacker News.
              </p>
              <div className="column-icon">
                <img
                  src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=%23F7DF1E"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/css3-%231572B6.svg?style=for-the-badge&logo=css3&logoColor=white"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/html5-%23E34F26.svg?style=for-the-badge&logo=html5&logoColor=white"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/netlify-%23000000.svg?style=for-the-badge&logo=netlify&logoColor=#00C7B7"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/figma-%23F24E1E.svg?style=for-the-badge&logo=figma&logoColor=white"
                  alt="icon"
                />
              </div>
              <div className="project-btn">
                <div className="github">
                  <a
                    href="https://github.com/gyuli-zoeykim/clone-hackernews"
                    target="_blank"
                    rel="noopener noreferrer">
                    <BsGithub className="project-btn-icon" />
                    <div className="project-btn-text">Github</div>
                  </a>
                </div>
                <div className="live">
                  <a
                    href="https://gyulizoeykimwork.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <BsGlobe2 className="project-btn-icon" />
                    <div className="project-btn-text">Live</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row two project-three">
            <div className="column one">
              <h5 className="project-title">Dog Friendly</h5>
              <p className="project-number three">03</p>
              <p className="section-description">
                Built a web application for dog owners who want to search, view
                detail and save dog-friendly places.
              </p>
              <div className="column-icon">
                <img
                  src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=%23F7DF1E"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/css3-%231572B6.svg?style=for-the-badge&logo=css3&logoColor=white"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/postgres-%23316192.svg?style=for-the-badge&logo=postgresql&logoColor=white"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/figma-%23F24E1E.svg?style=for-the-badge&logo=figma&logoColor=white"
                  alt="icon"
                />
                <img
                  src="https://img.shields.io/badge/AWS-%23FF9900.svg?style=for-the-badge&logo=amazon-aws&logoColor=white"
                  alt="icon"
                />
              </div>
              <div className="project-btn">
                <div className="github">
                  <a
                    href="https://github.com/gyuli-zoeykim/dog-friendly"
                    target="_blank"
                    rel="noopener noreferrer">
                    <BsGithub className="project-btn-icon" />
                    <div className="project-btn-text">Github</div>
                  </a>
                </div>
                <div className="live">
                  <a
                    href="https://dog-friendly.gkim.dev/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <BsGlobe2 className="project-btn-icon" />
                    <div className="project-btn-text">Live</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="column two image-wrapper">
              <div className="column-image">
                <a
                  href="https://dog-friendly.gkim.dev/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link">
                  <div className="image-overlay"></div>
                  <img
                    src={dogfriendly_mockup}
                    alt="project-preview"
                    className="hover-opacity"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="about-me container" ref={about}>
          <div className="row">
            <h3 className="section-title">About Me</h3>
            <div className="column profile-image-wrapper-mobile">
              <img src={profile} alt="profile" />
            </div>
          </div>
          <div className="row profile">
            <div className="column profile-image-wrapper-desktop">
              <img src={profile} alt="profile" />
            </div>
            <div className="column">
              <p className="section-description">
                I am a Full Stack Web Developer with experience in back end
                development, react.js, design, and project management.
              </p>
              <p className="section-description">
                As a former production and design assistant at a fashion
                company, I started learning and refining my software design and
                development skills by enhancing the user experience of our
                company's website. My role involved being hands on with various
                software development tasks ranging from designing and updating
                product listings to optimizing online sales performance. Through
                this experience, I learned the importance of online platforms
                and the impact of visual aesthetics on user engagement. It was
                during this time that I became inspired to transition into the
                world of software engineering, driven by my{' '}
                <span className="keyword">
                  passion for creating interactive and visually captivating web
                  applications
                </span>
                . I started working part time as a backend developer for
                Hydrasoft on a wide range of projects to gain experience and
                sharpen my skills.
              </p>
              <p className="section-description">
                To broaden my skill set, learn more front end development
                skills, and become a more well rounded full stack web developer,
                I completed a Full-Stack Web Development program. This not only
                sharpened my{' '}
                <span className="keyword">problem-solving skills</span> but also
                deepened my <span className="keyword">attention to detail</span>{' '}
                as a developer, building upon the foundation I had developed
                through my graphic design background. My design experience
                involved collaborating on branding projects with a diverse range
                of clients, encompassing tasks such as designing business cards,
                flyers, and banners. These client interactions strengthened my{' '}
                <span className="keyword">commitment to meeting deadlines</span>
                , improved my{' '}
                <span className="keyword">communication skills</span>, and
                taught me to be open to constructive{' '}
                <span className="keyword">feedback</span>.
              </p>
              <p className="section-description">
                By combining my design background with software engineering
                skills, I aim to create web applications efficiently and provide
                maximum value to users.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="column two">
              <h5>Stack</h5>
              <div className="column-stack translate">
                <div
                  className="icon"
                  style={{ backgroundImage: `url(${react_icon})` }}></div>
                <div
                  className="icon"
                  style={{ backgroundImage: `url(${javascript_icon})` }}></div>
                <div
                  className="icon"
                  style={{ backgroundImage: `url(${css_icon})` }}></div>
                <div
                  className="icon"
                  style={{ backgroundImage: `url(${html_icon})` }}></div>
                <div
                  className="icon"
                  style={{ backgroundImage: `url(${node_icon})` }}></div>
                <div
                  className="icon"
                  style={{ backgroundImage: `url(${express_icon})` }}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact container" ref={contact}>
          <div className="row">
            <h3 className="section-title">Contact</h3>
          </div>
          <div className="row">
            <p className="contact-message">
              Thank you for visiting my website. I am currently{' '}
              <span className="keyword">available</span> to work. Please feel
              free to contact me on social media or by email.
            </p>
            <div className="contact-btn">
              <div className="email">
                <a
                  href="mailto:gyulikimwork@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <BsFillSendFill className="contact-btn-icon" />
                  <div className="contact-btn-text">gyulikimwork@gmail.com</div>
                </a>
              </div>
              <div className="github">
                <a
                  href="https://github.com/gyuli-zoeykim"
                  target="_blank"
                  rel="noopener noreferrer">
                  <BsGithub className="contact-btn-icon" />
                  <div className="contact-btn-text">gyuli-zoeykim</div>
                </a>
              </div>
              <div className="linkedin">
                <a
                  href="https://www.linkedin.com/in/gyulikimwork/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <BsLinkedin className="contact-btn-icon" />
                  <div className="contact-btn-text">gyulikimwork</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
