import React from 'react';
import './MenuItems.css';
import { Link } from 'react-router-dom';

const MenuItems = ({ active, refs }) => {
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <li className={active} onClick={() => scrollToSection(refs.projects)}>
        <Link className="title">Projects</Link>
      </li>
      <li className={active} onClick={() => scrollToSection(refs.about)}>
        <Link className="title">About</Link>
      </li>
      <li className={active} onClick={() => scrollToSection(refs.contact)}>
        <Link className="title">Contact</Link>
      </li>
    </>
  );
};

export default MenuItems;
