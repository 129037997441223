import React, { useState, useEffect } from 'react';
import MenuItems from './MenuItems';
import './Header.css';
import { Link } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';

const Header = ({ refs, handleLogoClick }) => {
  const [open, setOpen] = useState(false);
  const [prevScroll, setPrevScroll] = useState(0);
  const [visible, setVisible] = useState(true);
  const [bgColor, setBgColor] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    handleLogoClick();
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScroll) {
        setVisible(false);
        setBgColor(false);
      } else {
        setVisible(true);
        setBgColor(currentScrollPos === 0 ? false : true);
      }

      setPrevScroll(currentScrollPos);
    };

    const handleTouchMove = () => {
      handleScroll();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchmove', handleTouchMove);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [prevScroll]);

  return (
    <div className="header">
      <div
        className={`logo-icon-wrapper ${
          visible || open ? 'visible' : 'hidden'
        }`}
        onClick={handleClick}>
        <Link className={'title'} to="/">
          <span className={`logo-text ${(bgColor || open) && 'color'}`}>
            Gyuli Kim
          </span>
        </Link>
      </div>
      <div className="menu-text-wrapper" onClick={handleToggle}>
        <h4 className={`menu-text ${bgColor && 'color'}`}>Menu</h4>
      </div>
      <div className={`bg-color ${bgColor ? 'visible' : 'hidden'}`}></div>
      <div className={`header-inner ${visible ? 'visible' : 'hidden'}`}>
        <div className="menu">
          <ul className={`menu-horizontal`}>
            <MenuItems
              active={`horizontal ${bgColor ? 'purple' : 'white'}`}
              refs={refs}
            />
          </ul>
          {open ? (
            <IoCloseSharp className="close-icon" onClick={handleToggle} />
          ) : null}
          <ul
            className={`menu-vertical ${open ? 'open' : ''}`}
            onClick={handleToggle}>
            <MenuItems active="vertical" refs={refs} />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
